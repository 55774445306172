import React from "react";

import transparentLogo from '../img/logotransparant.png'
import equinelogo from '../img/e-quine_logo_liggend.png'

const MoreInfo = () => {
  return (<>
    <section id="chefs" className="chefs">
      <div className="container">

        <div className="section-title">
          <h2>Meer <span>informatie ?</span></h2>
          <p>Neem gerust contact op. Of klik op de e-quine.com voor meer informatie over mij</p>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="h-full flex items-center p-6">
              <a target="_blank" href="https://e-quine.com/teachers/angela-jansen/"><img src={equinelogo}></img></a>
              
            </div>
          </div>


          <div className="col-6">
            <div className="p-6 h-full flex items-center">
              <img src={transparentLogo} className="img-fluid" alt="logo" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="flex justify-center items-center">
              <img src="" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section >

  </>)
}

export default MoreInfo;