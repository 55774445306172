import React from 'react';

import aboutImage from '../img/angela-jansen-paardengedrag.jpg';

const About = () => {
  return (
    <section id="about" className="about">
      <div className="container-fluid">

        <div className="row">

          <div className="col-lg-5 align-items-stretch">
            <img src={aboutImage}></img>
          </div>

          <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch">

            <div className="content">
              <h3>Mijn naam is Angela Jansen.</h3>
              <p>
                 Ik woon met mijn gezin in Noordwijk. De liefde voor paarden heb
                ik doorgegeven aan mijn dochter en samen hebben wij de zorg over onze twee shetlanders en fjord.
                Ik ben opgegroeid met paarden. Nu wilde ik meer leren over het gedrag en welzijn van paarden. Ik
                koos voor de 3,5 jarige durende opleiding “Instructeur Paard &amp; Gedrag” bij e-quine.com waar je
                wordt opgeleid tot gedragsdeskundige/trainer en instructeur.
              </p>
              {/* <p className="font-italic">
                Hier omschrijf je in één zin wat je voor potentiele klanten kan betekenen met belangrijke zoektermen en zoekwoorden waar mensen via google naar zoeken, consectetur adipiscing elit, sed dolore
                magna aliqua.
              </p> */}
              <ul>
                <li><i className="icofont-check"></i> Ik heb passie voor mijn vak, werken met paarden is geweldig, je bent nooit uitgeleerd!</li>
                <li><i className="icofont-check"></i> Ik breng mens en dier samen</li>
                <li><i className="icofont-check"></i> Ik maak van ” een probleem” een uitdaging</li>
              </ul>
              <p>
                Mijn missie is de paardenliefhebber te leren om samen te werken met het paard door middel van de
                juiste communicatie. Dit doe ik door leuke informatieve lessen en cursussen te geven. Of om jou en
                jouw paard met een gedragsuitdaging te ondersteunen.
              </p>
            </div>

          </div>

        </div>

      </div>
    </section>
  );
}

export default About;
